import React from 'react';
import { createProvider } from '@gluestack-ui/provider';
import { StyledProvider } from '@gluestack-style/react';
import { OverlayProvider } from '@gluestack-ui/overlay';
import { ToastProvider } from '@gluestack-ui/toast';
import { config } from './../gluestack-ui.config';
const GluestackUIStyledProvider = createProvider({ StyledProvider });
const GluestackUIProvider = ({ children, config: configProp = config.theme, ...props }) => {
    return (React.createElement(GluestackUIStyledProvider, { config: configProp, ...props },
        React.createElement(OverlayProvider, null,
            React.createElement(ToastProvider, null, children))));
};
export { GluestackUIProvider, GluestackUIStyledProvider };
